/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import Slider from "react-slick"
import Modal from "react-modal"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Lightbox from "react-image-lightbox"
import P1 from "../../img/policy/img60.png"
import P2 from "../../img/policy/img61.png"
import P3 from "../../img/policy/img62.png"
import P4 from "../../img/policy/img63.png"
import P5 from "../../img/policy/img64.png"
import P6 from "../../img/policy/img65.png"
import P7 from "../../img/policy/img66.png"
import P8 from "../../img/policy/img67.png"
import P9 from "../../img/policy/img68.png"
import P10 from "../../img/policy/img69.png"
import P11 from "../../img/policy/img70.png"
import P12 from "../../img/policy/img71.png"
import P13 from "../../img/policy/img72.png"

import HomeLogo from "../../components/HomeLogo"
import ModalStyle from "../../components/ModalStyle"

import PageWrapper from "../../layouts/PageWrapper"

import "react-image-lightbox/style.css"

const images = [P1, P2, P3, P4, P5, P6, P7, P8, P9, P10, P11, P12, P13]

class HSSEQPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state

    return (
      <PageWrapper>
        <Helmet>
          <title> HSSEQ - E.A. Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <HomeLogo />
                  <h1 className="title" id="title-line">
                    HSSEQ
                  </h1>
                  <h4 className="is-size-4 has-text-weight-bold">POLICY</h4>
                  <Link to="/hsseq">
                    <h4 className="is-size-7 has-text-weight-bold blue">
                      BULLETIN
                    </h4>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="columns is-multiline">
              {images.map((image, index) => (
                <div className="column is-3">
                  <img
                    className="pointer hsseq"
                    src={image}
                    onClick={() =>
                      this.setState({ isOpen: true, photoIndex: index })
                    }
                  />
                </div>
              ))}
            </div>

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            )}
          </div>
        </section>
      </PageWrapper>
    )
  }
}

HSSEQPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default HSSEQPage
